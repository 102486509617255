function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}import { actions } from "../../BasicReducer";
import { getDocument } from "./documentActions";
import referencesAPI from "../referencesAPI";
import relationTypesAPI from "../../RelationTypes/RelationTypesAPI";
import * as relationships from "../../Relationships/utils/routeUtils";

import { setReferences } from "./referencesActions";
import entitiesAPI from "../../Entities/EntitiesAPI";

export function setViewerState(state) {
  return (dispatch) => {
    const { documentViewer } = state;
    dispatch(actions.set('relationTypes', state.relationTypes));
    dispatch(actions.set('viewer/doc', documentViewer.doc));
    dispatch(actions.set('viewer/relationTypes', documentViewer.relationTypes));
    dispatch(actions.set('viewer/rawText', documentViewer.rawText));
    dispatch(setReferences(documentViewer.references));
    dispatch(relationships.setReduxState(state));
  };
}

export async function requestViewerState(requestParams, globalResources) {
  const { sharedId, raw, page } = requestParams.data;
  const defaultLanguage = globalResources.settings.collection.
  get('languages').
  find((l) => l.get('default'));

  const [doc, relationTypes, [connectionsGroups, searchResults, sort]] = await Promise.all([
  getDocument(
    requestParams.set({ sharedId }),
    defaultLanguage ? defaultLanguage.get('key') : 'en',
    requestParams.data.file
  ),
  relationTypesAPI.get(requestParams.onlyHeaders()),
  relationships.requestState(requestParams.set({ sharedId }), globalResources.templates)]
  );

  const { defaultDoc } = doc;
  const rawText = raw ?
  await entitiesAPI.getRawPage(requestParams.set({ _id: defaultDoc._id, page })) :
  '';

  const references = await referencesAPI.get(
    requestParams.set({ sharedId, file: doc.defaultDoc._id, onlyTextReferences: true })
  );

  return [
  setViewerState({
    documentViewer: {
      doc: _objectSpread(_objectSpread({},
      doc), {}, {
        relations: references }),

      references,
      relationTypes,
      rawText
    },
    relationships: {
      list: {
        sharedId: doc.sharedId,
        entity: doc,
        connectionsGroups,
        searchResults,
        sort,
        filters: {},
        view: 'graph'
      }
    },
    relationTypes
  })];

}