function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);} /* eslint-disable max-statements */
import { isString } from 'lodash';
import uniqueID from "../../../shared/uniqueID";

const prepareFiles = async (mediaProperties, values) => {
  const metadataFiles = {};
  const entityAttachments = [];
  const files = [];

  if (values.metadata || mediaProperties.length === 0) {
    await Promise.all(
      mediaProperties.map(async (p) => {
        if (!values.metadata[p.name] || /^https?:\/\//.test(values.metadata[p.name])) {
          return Promise.resolve();
        }
        const { data, originalFile } = values.metadata[p.name];
        if (originalFile) {
          const validBlobUrlRegExp =
          /^\(?(blob:https?:\/\/(?:www\.)?[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|])(, ({.+}))?/;

          const [, url,, timeLinks] = data.match(validBlobUrlRegExp) || ['', data];
          const blob = await fetch(url).then((r) => r.blob());
          const file = new File([blob], originalFile.name, { type: blob.type });
          const fileID = uniqueID();

          metadataFiles[p.name] = fileID;

          entityAttachments.push({
            originalname: file.name,
            filename: file.name,
            type: 'attachment',
            mimetype: blob.type,
            fileLocalID: fileID,
            timeLinks
          });

          files.push(file);

          return URL.revokeObjectURL(values.metadata[p.name]);
        }
      })
    );
  }

  return { metadataFiles, entityAttachments, files };
};

function wrapEntityMetadata(entity, template) {var _template$properties;
  const mediaProperties =
  ((_template$properties = template.properties) === null || _template$properties === void 0 ? void 0 : _template$properties.filter((prop) => prop.type === 'image' || prop.type === 'media')) || [];

  if (!entity.metadata) {
    return _objectSpread({}, entity);
  }
  const newFileMetadataValues = (entity.attachments || []).
  filter((attachment) => attachment.fileLocalID).
  reduce(
    (previousValue, attachment, index) => _objectSpread(_objectSpread({},
    previousValue), {}, {
      [attachment.fileLocalID]: { value: '', attachment: index, timeLinks: attachment.timeLinks } }),

    {}
  );

  const metadata = Object.keys(entity.metadata).reduce((wrappedMo, key) => {var _entity$metadata$key;
    let fileLocalID;
    let timeLinks;
    const property = mediaProperties.find((p) => p.name === key);
    if (property && entity.metadata[key]) {
      const fieldValue = entity.metadata[key].data || entity.metadata[key];
      const mediaExpGroups = fieldValue.match(/^\(?([\w+]{10,15})(, ({.+})\))?|$/);
      if (isString(fieldValue) && mediaExpGroups && mediaExpGroups[1]) {
        [, fileLocalID,, timeLinks] = mediaExpGroups || ['', fieldValue];
      }
      if (fileLocalID && fileLocalID.length < 20 && timeLinks) {
        newFileMetadataValues[fileLocalID] = _objectSpread(_objectSpread({}, newFileMetadataValues[fileLocalID]), {}, { timeLinks });
      }
    }
    const newFileMetadataValue = newFileMetadataValues[fileLocalID] || fileLocalID;
    return _objectSpread(_objectSpread({},
    wrappedMo), {}, {
      [key]: Array.isArray(entity.metadata[key]) ?
      entity.metadata[key].map((v) => ({ value: v })) :
      [newFileMetadataValue || { value: ((_entity$metadata$key = entity.metadata[key]) === null || _entity$metadata$key === void 0 ? void 0 : _entity$metadata$key.data) || entity.metadata[key] }] });

  }, {});
  // suggestedMetadata is always in metadata-object form.
  return _objectSpread(_objectSpread({}, entity), {}, { metadata });
}

const prepareMetadataAndFiles = async (values, attachedFiles, template) => {
  const mediaProperties = template.properties.filter((p) => p.type === 'image' || p.type === 'media');
  const { metadataFiles, entityAttachments, files } = await prepareFiles(mediaProperties, values);
  const fields = _objectSpread(_objectSpread({}, values.metadata), metadataFiles);
  const entity = _objectSpread(_objectSpread({}, values), {}, { metadata: fields, attachments: entityAttachments });
  const wrappedEntity = wrapEntityMetadata(entity, template);
  wrappedEntity.file = values.file ? values.file[0] : undefined;
  wrappedEntity.attachments = [];
  wrappedEntity.attachments.push(...files);
  wrappedEntity.attachments.push(...attachedFiles);
  return _objectSpread(_objectSpread({}, wrappedEntity), {}, { template: template._id });
};

export { prepareMetadataAndFiles, wrapEntityMetadata };