function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}function _objectDestructuringEmpty(obj) {if (obj == null) throw new TypeError("Cannot destructure " + obj);}function _extends() {_extends = Object.assign ? Object.assign.bind() : function (target) {for (var i = 1; i < arguments.length; i++) {var source = arguments[i];for (var key in source) {if (Object.prototype.hasOwnProperty.call(source, key)) {target[key] = source[key];}}}return target;};return _extends.apply(this, arguments);}import comonProperties from "../../../shared/comonProperties";
import prioritySortingCriteria from "../../utils/prioritySortingCriteria";
import { queryToFilter } from "./publishedStatusFilter";

function getOptions(property, thesauris) {
  const matchingTHesauri = thesauris.find((thesauri) => thesauri._id === property.content);
  return matchingTHesauri ? matchingTHesauri.values : null;
}

function populateOptions(filters, thesauris) {
  return filters.map((property) => {
    if (property.content) {
      return Object.assign(property, { options: getOptions(property, thesauris) });
    }

    if (!property.content && property.type === 'relationship') {
      return Object.assign(property, {
        options: thesauris.
        filter((t) => t.type === 'template').
        reduce((options, thesauri) => options.concat(thesauri.values), [])
      });
    }

    return property;
  });
}

function URLQueryToState(query, templates, forcedProps = []) {
  let properties = comonProperties.comonFilters(templates, query.types, forcedProps);
  if (!query.types || !query.types.length) {
    properties = comonProperties.defaultFilters(templates, forcedProps);
  }

  let { sort, order } = prioritySortingCriteria.get();
  ({ sort = sort, order = order } = query);
  const {
    searchTerm = '',
    filters = {},
    userSelectedSorting,
    includeUnpublished = true,
    unpublished = false,
    allAggregations = false,
    treatAs = 'number'
  } = query;

  return {
    properties,
    search: {
      searchTerm,
      filters,
      customFilters: query.customFilters,
      sort,
      order,
      userSelectedSorting,
      treatAs,
      publishedStatus: queryToFilter(unpublished, includeUnpublished),
      allAggregations
    }
  };
}

const normalizeBucket = (bucket) => {
  const normalizedBucket = {
    id: bucket.key,
    value: bucket.key,
    label: bucket.label,
    results: bucket.filtered.doc_count
  };

  if (bucket.icon) {
    normalizedBucket.icon = bucket.icon;
  }

  if (bucket.values) {
    normalizedBucket.options = bucket.values.map(normalizeBucket);
  }

  if (bucket.key === 'missing') {
    normalizedBucket.noValueKey = true;
  }

  return normalizedBucket;
};

export function parseWithAggregations(filters, aggregations, showNoValue = true) {
  return filters.map((_ref) => {let property = _extends({}, (_objectDestructuringEmpty(_ref), _ref));
    const propertyAggregations = aggregations.all[property.name];
    if (propertyAggregations && propertyAggregations.buckets) {
      property.options = propertyAggregations.buckets.
      map(normalizeBucket).
      filter((opt) => opt.results || !showNoValue && opt.value === 'missing');
    }

    return property;
  });
}

export const prepareDefaultFilters = (fields) =>
fields.map((field) => {
  if (!field.defaultfilter || !field.options) {
    return field;
  }
  const filteredOptions = field.options.filter((option) => option.id !== 'missing');
  return _objectSpread(_objectSpread({}, field), {}, { options: filteredOptions });
});

export default {
  URLQueryToState,
  populateOptions,
  parseWithAggregations
};

export { populateOptions };