function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}import { actions } from "../../BasicReducer";
import api from "../../Entities/EntitiesAPI";
import entitiesUtil from "../../Entities/utils/filterBaseProperties";
import { wrapEntityMetadata } from "../../Metadata";
import { loadFetchedInReduxForm } from "../../Metadata/actions/actions";
import * as relationships from "../../Relationships/utils/routeUtils";
import { RequestParams } from "../../utils/RequestParams";
import Immutable from 'immutable';




export async function getAndLoadEntity(
requestParams,
templates,
state,
loadConnections)
{
  const [[entity], [connectionsGroups, searchResults, sort, filters]] = await Promise.all([
  api.get(requestParams),
  loadConnections ?
  relationships.requestState(requestParams, state) :
  [[], { rows: [] }, {}, Immutable.fromJS({})]]
  );

  return [
  actions.set('entityView/entity', entity),
  relationships.setReduxState({
    relationships: {
      list: {
        sharedId: entity.sharedId,
        entity,
        connectionsGroups,
        searchResults,
        sort,
        filters,
        view: 'graph'
      }
    }
  }),
  ...loadFetchedInReduxForm('entityView.entityForm', entity, templates)];

}

export function toggleOneUpFullEdit() {
  return async (dispatch, getState) => {var _state$oneUpReview$st;
    const state = getState();
    const template = state.templates.filter(
      (t) => (t === null || t === void 0 ? void 0 : t.get('_id')) === state.entityView.entityForm.template
    );
    const oneUpState = (_state$oneUpReview$st = state.oneUpReview.state) === null || _state$oneUpReview$st === void 0 ? void 0 : _state$oneUpReview$st.toJS();
    if (oneUpState && oneUpState.fullEdit && !state.entityView.entityFormState.$form.pristine) {
      const entity = await api.denormalize(
        new RequestParams(
          wrapEntityMetadata(
            entitiesUtil.filterBaseProperties(state.entityView.entityForm),
            template
          )
        )
      );
      dispatch(actions.set('entityView/entity', entity));
    }
    dispatch(
      actions.set('oneUpReview.state', _objectSpread(_objectSpread({},
      oneUpState), {}, {
        fullEdit: oneUpState ? !oneUpState.fullEdit : false })
      )
    );
  };
}

async function switchToEntity(
dispatch,
index,
state,
oneUpState)
{
  const sharedId =
  index < oneUpState.totalDocs ?
  state.library.documents.get('rows').get(index).get('sharedId') :
  '';

  [
  ...(sharedId ?
  await getAndLoadEntity(
    new RequestParams({ sharedId }, oneUpState.requestHeaders),
    state.templates.toJS(),
    state,
    oneUpState.loadConnections
  ) :
  []),
  actions.set('oneUpReview.state', _objectSpread(_objectSpread({},
  oneUpState), {}, {
    indexInDocs: index })
  )].
  forEach((action) => {
    dispatch(action);
  });
}

export function switchOneUpEntity(delta, save) {
  return async (dispatch, getState) => {var _state$oneUpReview$st2;
    const state = getState();
    const template = state.templates.
    filter((t) => (t === null || t === void 0 ? void 0 : t.get('_id')) === state.entityView.entityForm.template).
    toJS();
    const oneUpState = (_state$oneUpReview$st2 = state.oneUpReview.state) === null || _state$oneUpReview$st2 === void 0 ? void 0 : _state$oneUpReview$st2.toJS();
    if (!oneUpState) {
      return;
    }
    if (save) {
      const entity = wrapEntityMetadata(
        entitiesUtil.filterBaseProperties(state.entityView.entityForm),
        template
      );
      await api.save(new RequestParams(entity, oneUpState.requestHeaders));
    }
    const current = state.entityView.entity.get('sharedId');
    const index = Math.max(
      0,
      Math.min(
        state.library.documents.get('rows').findIndex((e) => e.get('sharedId') === current) + delta,
        oneUpState.totalDocs - 1
      )
    );
    await switchToEntity(dispatch, index, state, oneUpState);
  };
}

export function toggleOneUpLoadConnections() {
  return async (dispatch, getState) => {var _state$oneUpReview$st3;
    const state = getState();
    const oneUpState = (_state$oneUpReview$st3 = state.oneUpReview.state) === null || _state$oneUpReview$st3 === void 0 ? void 0 : _state$oneUpReview$st3.toJS();
    if (!oneUpState) {
      return;
    }
    dispatch(
      actions.set('oneUpReview.state', _objectSpread(_objectSpread({},
      oneUpState), {}, {
        loadConnections: !oneUpState.loadConnections })
      )
    );
    await dispatch(switchOneUpEntity(0, false));
  };
}