function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}
import { formater as formatter } from "../Metadata";
import {
  pick,
  isArray,
  isObject,
  isEmpty,
  toPairs,
  take,
  get,
  groupBy,
  has,
  flatMap } from
'lodash';

















const pickEntityFields = (entity) =>
pick(entity, [
'title',
'sharedId',
'creationDate',
'editDate',
'language',
'template',
'inheritedProperty']
);

const metadataFields = (property) => {
  switch (property.type) {
    case 'geolocation':
      return { displayValue: 'value[0]', value: 'value' };
    default:
      return { displayValue: 'value', value: ['timestamp', 'originalValue', 'value'] };
  }
};

const propertyDisplayType = (property) => {
  switch (property.type) {
    case 'relationship':
      return 'inherit';
    default:
      return property.type;
  }
};

const formatPropertyValue = (
target,
metadataField) =>
{
  const availableProperties = pick(target, metadataField.value);
  const firstValue =
  isObject(availableProperties) && !isEmpty(availableProperties) ?
  take(toPairs(availableProperties), 1)[0][1] :
  target;
  return firstValue;
};

const formatProperty = (item) => {
  const values = !isArray(item.value) || !item.value.length ? [item] : item.value;
  const formattedItem = values.map((target) => {
    const relatedEntity = pickEntityFields(target.relatedEntity);
    const metadataField = metadataFields(item);
    const value = formatPropertyValue(target, metadataField);
    return _objectSpread({
      displayValue: get(target, metadataField.displayValue, value),
      value,
      name: item.name,
      type: propertyDisplayType(item) },
    !isEmpty(relatedEntity) ? { reference: relatedEntity } : {});

  });
  return formattedItem;
};

const formatAggregationsMetadata = (metadata) =>
Object.entries(metadata).reduce((memo, [propertyName, values]) => {
  const formmatedValues = values.map((value) =>
  value.label && value.label.length ? value.label : value.value
  );
  const result = { [propertyName]: formmatedValues };
  return _objectSpread(_objectSpread({}, memo), result);
}, {});

const aggregateByTemplate = (
relations,
relationship,
inheritingProperties) =>

relations.reduce(
  (groupedRelations, relation) => {
    const { template } = relation.entityData;
    const groupName = `${relationship.name}-${template}`;
    const relationMetadata = pick(
      relation.entityData.metadata,
      inheritingProperties[template]
    );
    const metadata = formatAggregationsMetadata(relationMetadata || {});
    const relatedEntity = {
      title: relation.entityData.title,
      sharedId: relation.entityData.sharedId,
      metadata
    };
    return !has(groupedRelations, groupName) ? _objectSpread(_objectSpread({},
    groupedRelations), {}, { [groupName]: [relatedEntity] }) : _objectSpread(_objectSpread({},
    groupedRelations), {}, { [groupName]: [...groupedRelations[groupName], relatedEntity] });
  },
  {}
);

const getInheritingProperties = (templates, entityTemplate) =>
templates.reduce((inheriting, template) => {var _entityTemplate$prope;
  const inheritedProperties = (_entityTemplate$prope = entityTemplate.properties) === null || _entityTemplate$prope === void 0 ? void 0 : _entityTemplate$prope.
  map((entityProperty) => {var _template$properties;
    if (!(entityProperty !== null && entityProperty !== void 0 && entityProperty.inherit)) return undefined;
    const inheritingPropertyName = (_template$properties = template.properties) === null || _template$properties === void 0 ? void 0 : _template$properties.find(
      (property) => {var _entityProperty$inher;return (property === null || property === void 0 ? void 0 : property._id) === (entityProperty === null || entityProperty === void 0 ? void 0 : (_entityProperty$inher = entityProperty.inherit) === null || _entityProperty$inher === void 0 ? void 0 : _entityProperty$inher.property);}
    );
    return inheritingPropertyName === null || inheritingPropertyName === void 0 ? void 0 : inheritingPropertyName.name;
  }).
  filter((value) => !!value);
  return inheritedProperties !== null && inheritedProperties !== void 0 && inheritedProperties.length ? _objectSpread(_objectSpread({},
  inheriting), {}, { [template._id]: inheritedProperties }) :
  inheriting;
}, {});

const filterInheritedRelations = (
entity,
inheritingProperties) =>
{
  const targetEntities = flatMap(entity.metadata, (property) =>
  property.value && property.value.length ?
  flatMap(property.value, (value) => value.relatedEntity) :
  []
  ).
  filter((v) => v).
  map((relatedEntity) => relatedEntity.sharedId);
  return entity.relations.filter(
    (relation) =>
    relation.entityData &&
    has(inheritingProperties, relation.entityData.template) &&
    targetEntities.includes(relation.entityData.sharedId)
  );
};

const aggregateRelationships = (
entity,
relationTypes,
entityTemplate,
_templates) =>
{
  if (!entity.relations || !entity.relations.length || !entityTemplate) {
    return {};
  }
  const templates = _templates.
  filter((template) => (template === null || template === void 0 ? void 0 : template.get('_id')) !== entityTemplate.get('_id')).
  toJS();

  const inheritingProperties = getInheritingProperties(templates, entityTemplate.toJS());
  const filteredRelations = filterInheritedRelations(entity, inheritingProperties);
  const relationshipGroups = groupBy(filteredRelations, 'template');

  const namedRelationshipGroups = Object.entries(relationshipGroups).reduce(
    (aggregated, [relationshipId, relations]) => {
      const relationship = relationTypes.find(({ _id }) => _id === relationshipId);
      if (relationship) {
        const aggregatedByTemplate = aggregateByTemplate(
          relations,
          relationship,
          inheritingProperties
        );
        return _objectSpread(_objectSpread({}, aggregated), aggregatedByTemplate);
      }
      return aggregated;
    },
    {}
  );

  return namedRelationshipGroups;
};

const formatEntityData = (
formattedEntity,
relationTypes,
entityTemplate,
templates) =>
{
  const entity = pickEntityFields(formattedEntity);

  const formattedMetadata = formattedEntity.metadata.reduce((memo, property) => {
    const formattedProperty = formatProperty(property);
    return _objectSpread(_objectSpread({}, memo), {}, { [property.name]: formattedProperty });
  }, {});

  const relationshipAggregations = aggregateRelationships(
    formattedEntity,
    relationTypes,
    entityTemplate,
    templates
  );

  return _objectSpread(_objectSpread({},
  entity), {}, {
    metadata: formattedMetadata,
    inherited_relationships: relationshipAggregations });

};

const formatEntity = (formattedEntity) => {
  const originalMetadata = formattedEntity.metadata;
  const metadata = originalMetadata.reduce(
    (memo, property) => _objectSpread(_objectSpread({}, memo), {}, { [property.name]: property }),
    {}
  );
  return _objectSpread(_objectSpread({}, formattedEntity), {}, { metadata });
};

const prepareAssets = (
entityRaw,
template,
state,
relationTypes) =>
{
  const formattedEntity = formatter.prepareMetadata(entityRaw, state.templates, state.thesauris);
  const entity = formatEntity(formattedEntity);
  const entityData = formatEntityData(formattedEntity, relationTypes, template, state.templates);
  return { entity, entityRaw, entityData, template: template.toJS() };
};

export { prepareAssets };