function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}import { Parser as HTMLParser } from 'htmlparser2';
import qs from 'qs';
import rison from 'rison-node';
import Big from 'big.js';

import searchApi from "../Search/SearchAPI";
import api from "../utils/api";
import entitiesApi from "../Entities/EntitiesAPI";

const conformUrl = ({ url = '', geolocation = false }) => {
  const { q } = qs.parse(url.substring(url.indexOf('?')), { ignoreQueryPrefix: true });

  if (!q) {
    const defaultValue = geolocation ?
    { allAggregations: true, limit: 0, geolocation: true } :
    { allAggregations: true, limit: 0 };

    return defaultValue;
  }

  const params = rison.decode(q);
  params.limit = 0;

  if (geolocation) {
    params.geolocation = true;
  }

  return params;
};

const conformValues = (attribs) => attribs.entity ? attribs : conformUrl(attribs);

const parseDatasets = (markdown) => {
  const result = {};
  const parser = new HTMLParser(
    {
      onopentag(name, attribs) {
        if (name === 'dataset') {
          result[attribs.name || 'default'] = conformValues(attribs);
        }
        if (name === 'query') {
          result[attribs.name || 'default'] = { url: attribs.url, query: true };
        }
      }
    },
    { decodeEntities: true }
  );

  parser.parseComplete(markdown);
  return result;
};

const requestDatasets = (datasets, requestParams) =>
Promise.all(
  Object.keys(datasets).map((name) => {
    if (datasets[name].query) {
      return api.
      get(datasets[name].url, requestParams).
      then((data) => ({ data: data.json, name, params: datasets[name] }));
    }
    const apiAction = datasets[name].entity ? entitiesApi.get : searchApi.search;
    const params = datasets[name].entity ? { sharedId: datasets[name].entity } : datasets[name];
    const postAction = datasets[name].entity ? (d) => d[0] : (d) => d;
    return apiAction(requestParams.set(params)).
    then(postAction).
    then((data) => ({ data, name, params: datasets[name] }));
  })
);

const conformDatasets = (sets) =>
sets.reduce((memo, set) => {
  const data = set.params.extractFirstRow ? set.data.rows[0] : set.data;
  return _objectSpread(_objectSpread({}, memo), {}, { [set.name]: data });
}, {});

const getAggregations = (state, { property, dataset = 'default' }) => {
  const data = state.page.datasets.get(dataset);
  return !data ? undefined : data.getIn(['aggregations', 'all', property, 'buckets']);
};

const addValues = (aggregations, values) => {
  let result = new Big(0);
  values.forEach((key) => {
    const value = aggregations.find((bucket) => bucket.get('key') === key);
    const filteredValue = value ? value.getIn(['filtered', 'doc_count']) : 0;
    result = result.plus(filteredValue || 0);
  });
  return Number(result);
};

export default {
  async fetch(markdown, requestParams, { additionalDatasets = {} } = {}) {
    const datasets = parseDatasets(markdown);
    const extendedDatsets = _objectSpread(_objectSpread({}, datasets), additionalDatasets);
    return requestDatasets(extendedDatsets, requestParams).then(conformDatasets);
  },

  getRows(state, { dataset = 'default' }) {
    const data = state.page.datasets.get(dataset);
    if (!data) {
      return undefined;
    }
    return data.get('rows');
  },

  getAggregations,

  getAggregation(state, { uniqueValues, property, value, dataset = 'default' }) {
    const aggregations = getAggregations(state, { property, dataset });
    if (!aggregations) {
      return undefined;
    }

    if (uniqueValues) {
      return aggregations.filter((a) => a.getIn(['filtered', 'doc_count']) !== 0).size;
    }

    const values = value ? value.split(',') : [''];
    return addValues(aggregations, values);
  },

  getMetadataValue(state, { property, dataset = 'default' }) {
    const data = state.page.datasets.get(dataset);
    const propertyExists = data && data.hasIn(['metadata', property]);
    const mos = propertyExists ? data.getIn(['metadata', property]).toJS() : [];
    return mos && mos.length && mos[0].value ? Number(mos[0].value) : undefined;
  }
};