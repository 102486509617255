function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);}


const update = (
entity,
data) =>



{
  const updatedEntity = _objectSpread({}, entity);

  if (!data) return entity;

  const { title, properties } = data;

  if (title) {
    updatedEntity.title = title;
  }

  if (properties !== null && properties !== void 0 && properties.length) {
    properties.forEach((property) => {var _updatedEntity$metada;
      const [propertyName] = Object.keys(property);

      const hasProperty = updatedEntity.metadata ? (_updatedEntity$metada =
      updatedEntity.metadata[propertyName]) === null || _updatedEntity$metada === void 0 ? void 0 : _updatedEntity$metada.length :
      false;

      if (hasProperty) {
        if (property[propertyName]) {
          updatedEntity.metadata[propertyName][0].value = property[propertyName];
        }

        if (!property[propertyName]) {
          delete updatedEntity.metadata[propertyName];
        }
      }

      if (!hasProperty && property[propertyName]) {var _propertyName;
        updatedEntity.metadata[propertyName] = [];
        (_propertyName = updatedEntity.metadata[propertyName]) === null || _propertyName === void 0 ? void 0 : _propertyName.push({ value: property[propertyName] });
      }
    });
  }

  return updatedEntity;
};

export { update };