function ownKeys(e, r) {var t = Object.keys(e);if (Object.getOwnPropertySymbols) {var o = Object.getOwnPropertySymbols(e);r && (o = o.filter(function (r) {return Object.getOwnPropertyDescriptor(e, r).enumerable;})), t.push.apply(t, o);}return t;}function _objectSpread(e) {for (var r = 1; r < arguments.length; r++) {var t = null != arguments[r] ? arguments[r] : {};r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {_defineProperty(e, r, t[r]);}) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));});}return e;}function _defineProperty(obj, key, value) {key = _toPropertyKey(key);if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}function _toPropertyKey(arg) {var key = _toPrimitive(arg, "string");return typeof key === "symbol" ? key : String(key);}function _toPrimitive(input, hint) {if (typeof input !== "object" || input === null) return input;var prim = input[Symbol.toPrimitive];if (prim !== undefined) {var res = prim.call(input, hint || "default");if (typeof res !== "object") return res;throw new TypeError("@@toPrimitive must return a primitive value.");}return (hint === "string" ? String : Number)(input);} /** @format */

import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/*
StateSelector allows making sub-components state-dependent without
adding that state to the parent's props, thus reducing the re-rendering of
the parent.

Usage:
class Parent extends Component {
  render() {
    return (
      <div>
        <Static Parts ... />
        <StateSelector myVal={createSelector(state => state.path.to.obj, value => value)}>
          {({ myVal }) => (<Dynamic Parts propVal={myVal} ... />)}
        </StateSelector>
        <More Static Parts ... />
      </div>
    );
  }
}
*/
export class StateSelectorBase extends Component {
  render() {
    return this.props.children(this.props);
  }
}

StateSelectorBase.defaultProps = {
  isPristine: undefined
};







export const StateSelector = connect((state, ownProps) =>
Object.keys(ownProps).
filter((k) => !['children'].includes(k)).
reduce((res, k) => _objectSpread(_objectSpread({}, res), {}, { [k]: ownProps[k](state) }), {})
)(StateSelectorBase);